import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  apiUrl: string = 'https://api.culturao.com.br';
  menuOpen: boolean = false;

  modalGaleriaSubject = new Subject<boolean>();

  constructor() { }

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

}
