export class Depoimento {
    
    id: any;
    foto: string = '';
    nome: string = '';
    descritivo: string = '';
    depoimento: string = '';
    data: Date = new Date();
    
    [x: string]: any;
    constructor(obj?) {
        Object.assign(this);
    }
}