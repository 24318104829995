import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import { Fato } from 'src/app/models/fato.model';

@Injectable({
  providedIn: 'root'
})
export class FatoService {

  constructor(
    public api: ApiService
  ) { }

  get(): Observable<Fato> {
    return this.api.get('/fato/buscar');
  }

  patch(fato: Fato): Observable<any> {
    return this.api.post('/fato/alterar', fato);
  }

  postFile(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }
}
