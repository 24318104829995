import { Banner } from './../../models/banner.model';
import { Observable } from 'rxjs';
import { ApiService } from './../api.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BannerService {

  constructor(
    public api: ApiService
  ) { }

  get(): Observable<Banner> {
    return this.api.get('/banner/buscar');
  }

  patch(banner: Banner): Observable<any> {
    return this.api.post('/banner/alterar', banner);
  }

  postFile(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }
}
