export class Noticia {

    id: number;
    titulo: string = '';
    descricaoSimples: string = '';
    descricao: string = '';
    data: Date = new Date();
    slug: string = '';
    tag: string = '';
    tempoLeitura: string = '1 minutos(s) para ler';
    imagemPequena: string = '';
    imagemGrande: string = '';
    situacao: string = 'A';
    [x: string]: any;

    constructor(obj?) {
        Object.assign(this);
    }
}