import { IsLoggedInGuard } from './guards/is-logged-in.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// pages
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { UsuariosEditarComponent } from './pages/usuarios-editar/usuarios-editar.component';
import { UsuariosCriarComponent } from './pages/usuarios-criar/usuarios-criar.component';
import { UsuariosComponent } from './pages/usuarios/usuarios.component';
import { ConfiguracoesComponent } from './pages/configuracoes/configuracoes.component';
import { ContatoComponent } from './pages/contato/contato.component';
import { NoticiasComponent } from './pages/noticias/noticias.component';
import { NoticiasCriarComponent } from './pages/noticias-criar/noticias-criar.component';
import { NoticiasEditarComponent } from './pages/noticias-editar/noticias-editar.component';
import { SobreComponent } from './pages/sobre/sobre.component';
import { BeneficiosComponent } from './pages/beneficios/beneficios.component';
import { BeneficiosCriarComponent } from './pages/beneficios-criar/beneficios-criar.component';
import { BeneficiosEditarComponent } from './pages/beneficios-editar/beneficios-editar.component';
import { BannerComponent } from './pages/banner/banner.component';
import { FatoComponent } from './pages/fato/fato.component';
import { CursosComponent } from './pages/cursos/cursos.component';
import { CursosCriarComponent } from './pages/cursos-criar/cursos-criar.component';
import { CursosEditarComponent } from './pages/cursos-editar/cursos-editar.component';
import { DepoimentosComponent } from './pages/depoimentos/depoimentos.component';
import { DepoimentosCriarComponent } from './pages/depoimentos-criar/depoimentos-criar.component';
import { DepoimentosEditarComponent } from './pages/depoimentos-editar/depoimentos-editar.component';


const routes: Routes = [
  { path: '', redirectTo: '/', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: '', component: HomeComponent, canActivate: [IsLoggedInGuard] },
  { path: 'contato', component: ContatoComponent, canActivate: [IsLoggedInGuard] },
  { path: 'configuracoes', component: ConfiguracoesComponent, canActivate: [IsLoggedInGuard] },
  { path: 'usuarios', component: UsuariosComponent, canActivate: [IsLoggedInGuard] },
  { path: 'usuarios/adicionar', component: UsuariosCriarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'usuarios/editar/:id', component: UsuariosEditarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'noticias', component: NoticiasComponent, canActivate: [IsLoggedInGuard] },
  { path: 'noticias/adicionar', component: NoticiasCriarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'noticias/editar/:id', component: NoticiasEditarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'beneficios', component: BeneficiosComponent, canActivate: [IsLoggedInGuard] },
  { path: 'beneficios/adicionar', component: BeneficiosCriarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'beneficios/editar/:id', component: BeneficiosEditarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'sobre', component: SobreComponent, canActivate: [IsLoggedInGuard] },
  { path: 'fatos', component: FatoComponent, canActivate: [IsLoggedInGuard] },
  { path: 'banner', component: BannerComponent, canActivate: [IsLoggedInGuard] },
  { path: 'cursos', component: CursosComponent, canActivate: [IsLoggedInGuard] },
  { path: 'cursos/adicionar', component: CursosCriarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'cursos/editar/:id', component: CursosEditarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'depoimentos', component: DepoimentosComponent, canActivate: [IsLoggedInGuard] },
  { path: 'depoimentos/adicionar', component: DepoimentosCriarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'depoimentos/editar/:id', component: DepoimentosEditarComponent, canActivate: [IsLoggedInGuard] },
  { path: '**', redirectTo: '/not-found' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
