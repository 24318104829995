export class Beneficio {
    id: 1;
    icone: string = '';
    nome: string = '';
    ordem: number = 99;
    [x: string]: any;
    constructor(obj?) {
        Object.assign(this);
    }

}