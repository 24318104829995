export class Usuario {

    id: number;
    imagemPerfil?: string = '';
    codigo: string = '';
    nome: string;
    sobrenome: string;
    email: string;
    senha: string;
    situacao: string = 'A';
    solicitaAlteracao: string = '';
    codigoRecuperacao: string = '';
    playerId: string = '';
    [x: string]: any;

    constructor(obj?) {

    }
}