import { NgModule } from '@angular/core';
// locale
import { LOCALE_ID } from '@angular/core';
import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localePt);
// Material
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { getBrazilianPaginatorIntl } from './brazilian-paginator-intl';
import { MatSortModule } from '@angular/material/sort';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material';
import { MatTabsModule } from '@angular/material/tabs';
// imports
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { NgxMaskModule } from 'ngx-mask';
import { NgxCurrencyModule } from "ngx-currency";
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxSummernoteModule } from 'ngx-summernote';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxPhoneMaskBrModule } from 'ngx-phone-mask-br';
// components
import { DialogComponent } from 'src/app/components/dialog/dialog.component';
import { LoadingComponent } from './components/loading/loading.component';
import { HeaderComponent } from './components/header/header.component';
import { ModalResetPasswordComponent } from './components/modal-reset-password/modal-reset-password.component';
// pages
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { UsuariosEditarComponent } from './pages/usuarios-editar/usuarios-editar.component';
import { UsuariosCriarComponent } from './pages/usuarios-criar/usuarios-criar.component';
import { UsuariosComponent } from './pages/usuarios/usuarios.component';
import { ConfiguracoesComponent } from './pages/configuracoes/configuracoes.component';
import { ContatoComponent } from './pages/contato/contato.component';
import { NoticiasComponent } from './pages/noticias/noticias.component';
import { NoticiasCriarComponent } from './pages/noticias-criar/noticias-criar.component';
import { NoticiasEditarComponent } from './pages/noticias-editar/noticias-editar.component';
import { SobreComponent } from './pages/sobre/sobre.component';
import { FilterPipe } from './pipes/filter.pipe';
import { MensagensComponent } from './pages/mensagens/mensagens.component';
import { BeneficiosComponent } from './pages/beneficios/beneficios.component';
import { BeneficiosCriarComponent } from './pages/beneficios-criar/beneficios-criar.component';
import { BeneficiosEditarComponent } from './pages/beneficios-editar/beneficios-editar.component';
import { BannerComponent } from './pages/banner/banner.component';
import { FatoComponent } from './pages/fato/fato.component';
import { CursosComponent } from './pages/cursos/cursos.component';
import { CursosCriarComponent } from './pages/cursos-criar/cursos-criar.component';
import { CursosEditarComponent } from './pages/cursos-editar/cursos-editar.component';
import { DepoimentosComponent } from './pages/depoimentos/depoimentos.component';
import { DepoimentosCriarComponent } from './pages/depoimentos-criar/depoimentos-criar.component';
import { DepoimentosEditarComponent } from './pages/depoimentos-editar/depoimentos-editar.component';

export const customCurrencyMaskConfig = {
  align: "left",
  allowNegative: true,
  allowZero: true,
  decimal: ",",
  precision: 2,
  prefix: "R$ ",
  suffix: "",
  thousands: ".",
  nullable: true
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ModalResetPasswordComponent,
    DialogComponent,
    LoadingComponent,
    // pages
    HomeComponent,
    LoginComponent,
    ConfiguracoesComponent,
    ContatoComponent,
    UsuariosComponent,
    UsuariosCriarComponent,
    UsuariosEditarComponent,
    NoticiasComponent,
    NoticiasCriarComponent,
    NoticiasEditarComponent,
    SobreComponent,
    FilterPipe,
    MensagensComponent,
    BeneficiosComponent,
    BeneficiosCriarComponent,
    BeneficiosEditarComponent,
    BannerComponent,
    FatoComponent,
    CursosComponent,
    CursosCriarComponent,
    CursosEditarComponent,
    DepoimentosComponent,
    DepoimentosCriarComponent,
    DepoimentosEditarComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    NgxSummernoteModule,
    NgxMaskModule.forRoot(),
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    NgxMatSelectSearchModule,
    NgxPhoneMaskBrModule,
    // Material
    MatInputModule,
    MatFormFieldModule,
    MatSnackBarModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatIconModule,
    MatMenuModule,
    MatProgressBarModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatDialogModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTabsModule,
    NgxDropzoneModule,
  ],
  entryComponents: [
    DialogComponent,
    ModalResetPasswordComponent
  ],
  providers: [
    { provide: MatPaginatorIntl, useValue: getBrazilianPaginatorIntl() },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
